import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useQuery } from "@tanstack/react-query";

const TopBar = () => {
  return (
    <div className="flex flex-row mx-auto w-full my-8 justify-center">
      <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">
        Applications
      </h1>
    </div>
  );
};
const fetchApplications = async () => {
  const res = await axios.get("/api/application/allapplications");
  return res.data;
};

const fetchAdminDetails = async (email) => {
  const res = await axios.get(`/api/data/admin/${email}`);
  return res.data;
};

const ApplicationList = () => {
  // const [applications, setApplications] = useState([]);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  // const [adminDetails, setAdminDetails] = useState({});

  const { data: adminDetails, isLoading: isAdminLoading } = useQuery({
    queryKey: ["adminDetails", currentUser?.email],
    queryFn: () => fetchAdminDetails(currentUser.email),
    enabled: !!currentUser?.email,
  });
  const { data: applications, isLoading: isApplicationsLoading } = useQuery({
    queryKey: ["applications", adminDetails],
    queryFn: fetchApplications,
    enabled: !!currentUser?.email && !!adminDetails,
    select: (data) => {
      let filteredApplications = [];
      if (adminDetails?.hitgenAdmin) {
        filteredApplications = data.filter(
          (app) => app.termsAndConditions.hitgenAccepted === true
        );
        filteredApplications = sortByAccepted(filteredApplications, "hitgen");
      } else if (adminDetails?.xchemAdmin) {
        filteredApplications = data.filter(
          (app) => app.termsAndConditions.xchemAccepted === true
        );
        filteredApplications = sortByAccepted(filteredApplications, "xchem");
      } else {
        filteredApplications = data;
        filteredApplications = sortByAccepted(filteredApplications, "xchem");
      }
      return filteredApplications;
    },
  });

  const handleRowClick = (rowData) => {
    // Redirect to a different page with the clicked row's data
    navigate(`/single-application/${rowData.id}`);
  };

  function sortByAccepted(applications, provider) {
    const decisionField =
      provider === "xchem" ? "xchemAccepted" : "hitgenAccepted";

    return applications.sort((a, b) => {
      const aDecision = a.decision[decisionField];
      const bDecision = b.decision[decisionField];

      // First, compare the provider's decision
      if (aDecision === bDecision) {
        // If decisions are the same, sort by dateSubmitted (most recent first)
        return new Date(b.dateSubmitted) - new Date(a.dateSubmitted);
      }

      // null comes first
      if (aDecision === null) return -1;
      if (bDecision === null) return 1;

      // false comes before true
      return aDecision - bDecision;
    });
  }
  if (!currentUser) {
    navigate("/datasets");
  }

  if (isAdminLoading || isApplicationsLoading) {
    return (
      <div className="h-screen flex justify-content-center items-center">
        <ProgressSpinner
          style={{ width: "250px", height: "250px" }}
          strokeWidth="6"
        />
      </div>
    );
  }

  return (
    <div className="min-h-full md:min-h-screen items-center py-20 px-4 md:px-32">
      <TopBar />
      <DataTable
        value={applications}
        paginator
        rows={20}
        rowsPerPageOptions={[20, 30, 50, 100]}
        onRowClick={(event) => handleRowClick(event.data)}
      >
        <Column
          field="submittedInfo.orgName"
          header="Organization"
          alignHeader="center"
          sortable
          body={(rowData) => {
            const isIndividual = !rowData.submittedInfo.orgName;
            return (
              <span style={{ color: isIndividual ? "#8971d0" : "#323232" }}>
                {isIndividual
                  ? "Individual Application"
                  : rowData.submittedInfo.orgName}
              </span>
            );
          }}
        />
        <Column
          field="submittedInfo.useDescription"
          header="Description"
          alignHeader={"center"}
        />
        <Column
          field="submittedInfo.adminFirstName"
          header="Submitter Name"
          body={(rowData) => {
            return rowData.submittedInfo
              ? `${rowData.submittedInfo.adminFirstName} ${rowData.submittedInfo.adminLastName}`
              : "";
          }}
          alignHeader={"center"}
          sortable
        />
        <Column
          field="submitterEmail"
          header="Submitter Email"
          alignHeader={"center"}
          sortable
        />
        {(adminDetails?.superAdmin || adminDetails?.hitgenAdmin) && (
          <Column
            body={(rowData) => (
              <span
                className={`w-full flex items-center justify-center text-center text-sm md:mb-0 ${
                  rowData.termsAndConditions.hitgenAccepted
                    ? rowData.decision.hitgenAccepted
                      ? "text-green-500"
                      : "text-red-500"
                    : "text-gray-500"
                }`}
              >
                {rowData.termsAndConditions.hitgenAccepted ? (
                  rowData.decision.hitgenAccepted !== null ? (
                    rowData.decision.hitgenAccepted === true ? (
                      <b>Accepted</b>
                    ) : rowData.decision.hitgenAccepted === false ? (
                      <b>Rejected </b>
                    ) : (
                      String(rowData.decision.hitgenAccepted)
                    )
                  ) : (
                    "No Decision Made"
                  )
                ) : (
                  "N/A"
                )}
              </span>
            )}
            header="HitGen Status"
            alignHeader={"center"}
          />
        )}
        {(adminDetails?.superAdmin || adminDetails?.xchemAdmin) && (
          <Column
            body={(rowData) => (
              <span
                className={`w-full flex items-center justify-center text-center text-sm md:mb-0 ${
                  rowData.termsAndConditions.xchemAccepted
                    ? rowData.decision.xchemAccepted
                      ? "text-green-500"
                      : "text-red-500"
                    : "text-gray-500"
                }`}
              >
                {rowData.termsAndConditions.xchemAccepted ? (
                  rowData.decision.xchemAccepted !== null ? (
                    rowData.decision.xchemAccepted === true ? (
                      <b>Accepted </b>
                    ) : rowData.decision.xchemAccepted === false ? (
                      <>
                        <b>Rejected</b>
                      </>
                    ) : (
                      String(rowData.decision.xchemAccepted)
                    )
                  ) : (
                    "No Decision Made"
                  )
                ) : (
                  "N/A"
                )}
              </span>
            )}
            header="X-Chem Status"
            alignHeader={"center"}
          />
        )}
      </DataTable>
    </div>
  );
};

export default ApplicationList;
