import React from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoutes, ApplicationRoute } from "./protectRoutes";
import {
  Home,
  Datasets,
  Application,
  ApplicationList,
  SingleApplication,
  NotFoundPage,
  ViewApplication,
  Readmd,
  FeedbackForm,
  Stats,
  DisplayModels,
} from "./pages/index";

function BasicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/datasets" element={<Datasets />} />
      <Route path="/models" element={<DisplayModels />} />
      <Route path="/stats" element={<Stats />} />
      <Route path="/feedbackform" element={<FeedbackForm />} />
      <Route path="/readme" element={<Readmd />} />
      <Route element={<ApplicationRoute />}>
        <Route path="/application" element={<Application />} />
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route path="/application-list" element={<ApplicationList />} />
        <Route path="/single-application/:applicationId" element={<SingleApplication />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/view-application/:applicationId" element={<ViewApplication />} />
    </Routes>
  );
}

export default BasicRoutes;
